import React, { Component } from 'react';
import Slider from 'react-slick';
import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';
import TestimFamily from '../../images/testimfamily.png';

const settings = {
    dots: true,
    infinite: true,
    speed: 1500,
    autoplay: true,
    arrows: false,
    slidesToShow: 1,
    slidesToScroll: 1,
};

const dotsSettings = {
    dots: false,
    autoplay: true,
    infinite: true,
    speed: 2000,
    arrows: false,
    slidesToShow: 9,
    slidesToScroll: 1,
    focusOnSelect: true,
};
class WhatParentsSay extends Component {
    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    render() {
        return (
            <section className="page-section bg-secondary text-white testimsec">
                <h3 className="yellowtxt">Here’s what people are saying about We Rock the Spectrum Kids Gyms around the world...</h3>
                <div
                    id="myCarousel"
                    className="carousel slide"
                    data-ride="carousel"
                >
                    <Slider
                        asNavFor={this.state.nav2}
                        ref={slider => (this.slider1 = slider)}
                        {...dotsSettings}
                        className="carousel-indicators"
                    >
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                        <li></li>
                    </Slider>
                    <Slider
                        {...settings}
                        asNavFor={this.state.nav1}
                        ref={slider => (this.slider2 = slider)}
                        className="carousel-inner"
                    >
                        <div className="item carousel-item active">
                            <p className="testimonial">
                                “Staff have excellent knowledge to support kids 
                                with ASD and additional needs. They are loving 
                                and supportive. Highly recommend.”
                            </p>
                            {/* <p className="overview">Jenny R. - Winnetka</p> */}
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Absolutely fantastic place, they go above and beyond. 
                                A place you never have to say sorry. 
                                My daughter adores rock the spectrum. Thankgod we 
                                have one in Geelong.”
                            </p>
                            <p className="overview">Emma B</p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            “What a fantastic facility. The owners are just wonderful and welcoming! 
                            My 8yr old ASD son had the best time.... so many choices. 
                            While my 3 yr old loved playing with the shopping cart 
                            playing supermarkets and dressing up as a fireman. 
                            I highly recommend this wonderful place for the whole family. 
                            Thanks guys!”
                            </p>
                            {/* <p className="overview">
                                Ken & Monique F. - Tarzana
                            </p> */}
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Wonderful! So many options for different skill levels, 
                                needs and preferences. We will definitely be back."
                            </p>
                            <p className="overview">
                            Bec K
                            </p>
                        </div>

                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Amazing space with oodles of engaging equipment and activities!! 
                                So considered and lovely staff. My boys loved it!"
                            </p>
                            <p className="overview">
                            Vicki V
                            </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “We absolutely love this place! The facilities are amazing and 
                                the team that work here are so welcoming and great with the children. 
                                It has been an absolute life saver for us throughout 
                                these tricky times being able to use the facilities for private hire!"
                            </p>
                            <p className="overview">
                            Bree T
                            </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                                “Highly recommended for all you child's sensory needs. 
                                Great friendly welcoming space"
                            </p>
                            <p className="overview">
                            Charlene L
                            </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            What a terrific space for our little people. 
                            We are looking forward to visiting again. Great value for $$"
                            </p>
                            <p className="overview">
                            Jessica A
                            </p>
                        </div>
                        <div className="item carousel-item">
                            <p className="testimonial">
                            Great place, plenty of things to keep the kids busy"
                            </p>
                            <p className="overview">
                            Jackie M
                            </p>
                        </div>
                    </Slider>
                    <img
                        className="quoteimg"
                        src={TestimFamily}
                        alt="Testimonials family"
                    />
                </div>
            </section>
        );
    }
}

export default WhatParentsSay;
